import React, { useEffect, useState } from 'react'
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { Routes, Route,useNavigate } from "react-router-dom"
import Summary from './components/Summary/Summary'
import Plans from './components/Plans/Plans';
import CategorySummary from './components/category-summary/category-summary'
import { IMAGECONFIG } from './helpers/image-config';
import history from './helpers/history';
import './App.css'
import KeyAccounts from './components/key-accounts/key-accounts';
import KeyFinancials from './components/key-financials/key-financials';

const useStyles = makeStyles((theme) => ({
  drawerPaper: { width: 'inherit' },
  link: { textDecoration: 'none', theme: theme.palette.secondary.light }
}))

function App() {

  const navigate = useNavigate()
  const classes = useStyles();
  const [active_item, setActiveItem] = useState('')

  const updateHistory = () => {
    let is_first_render = history.location.pathname === '/'
    if (is_first_render) {
      navigate('/plans')
      setActiveItem('/plans')
    } else {
      navigate(history.location.pathname)
      setActiveItem(history.location.pathname)
    }
  }

  useEffect(() => {
    // let is_first_render = history.location.pathname === '/'
    // if (is_first_render) {
    //   history.replace('/plans')
    //   setActiveItem('/plans')
    // } else {
    //   history.push(history.location.pathname)
    //   setActiveItem(history.location.pathname)
    // }
    updateHistory()
  }, [])

  const handleNavigation = (path_name) => {
    navigate(path_name)
    setActiveItem(path_name)
  }

  return (
    <div style={{ display: "flex" }}>
      <Drawer
        style={{ width: '20%' }}
        variant='persistent'
        anchor='left'
        open={true}
        classes={{ paper: classes.drawerPaper }}>
        <List>
          <img src={IMAGECONFIG.NAVIGATION.LOGO} />
          <ListItem button onClick={() => handleNavigation('/plans')} className={active_item === '/plans' ? 'active-list-item list-item' : 'list-item'}>
            <ListItemIcon>
              <img src={active_item !== '/plans' ?IMAGECONFIG.NAVIGATION.PLANSLOGO:IMAGECONFIG.NAVIGATION.PLANSLOGOACTIVE} />
            </ListItemIcon>
            <ListItemText primary='Plans' />
          </ListItem>
          <ListItem button onClick={() => handleNavigation('/summary')} className={active_item === '/summary' ? 'active-list-item list-item' : 'list-item'}>
            <ListItemIcon>
              <img src={active_item !== '/summary' ?IMAGECONFIG.NAVIGATION.SUMMARYLOGO:IMAGECONFIG.NAVIGATION.SUMMARYLOGOACTIVE} />
            </ListItemIcon>
            <ListItemText primary='Executive Dashboard' />
          </ListItem>
          <ListItem button onClick={() => handleNavigation('/category-summary')} className={active_item === '/category-summary' ? 'active-list-item list-item' : 'list-item'}>
            <ListItemIcon>
              <img src={active_item !== '/category-summary' ?IMAGECONFIG.NAVIGATION.CATEGORYSUMMARYLOGO:IMAGECONFIG.NAVIGATION.CATEGORYSUMMARYLOGOACTIVE} />
            </ListItemIcon>
            <ListItemText primary='Category Deepdive' />
          </ListItem>
          <ListItem button onClick={() => handleNavigation('/key-accounts')} className={active_item === '/key-accounts' ? 'active-list-item list-item' : 'list-item'}>
            <ListItemIcon>
              <img src={active_item !== '/key-accounts' ?IMAGECONFIG.NAVIGATION.KEYACCOUNTSLOGO:IMAGECONFIG.NAVIGATION.KEYACCOUNTSLOGOACTIVE} />
            </ListItemIcon>
            <ListItemText primary='Account Deepdive' />
          </ListItem>
          <ListItem button onClick={() => handleNavigation('/financial-summary')} className={active_item === '/financial-summary' ? 'active-list-item list-item' : 'list-item'}>
            <ListItemIcon>
              <img src={active_item !== '/financial-summary' ?IMAGECONFIG.NAVIGATION.FINANCIALSUMMARYLOGO:IMAGECONFIG.NAVIGATION.FINANCIALSUMMARYLOGOACTIVE} />
            </ListItemIcon>
            <ListItemText primary='Financial Deepdive' />
          </ListItem>
          <ListItem button className={active_item === '/glossary' ? 'active-list-item list-item' : 'list-item'}>
            <ListItemIcon>
              <img src={IMAGECONFIG.NAVIGATION.GLOSSARYLOGO} />
            </ListItemIcon>
            <ListItemText primary='Glossary' />
          </ListItem>
          <Divider />
          <ListItem button className={active_item === '/settings' ? 'active-list-item list-item' : 'list-item'}>
            <ListItemIcon>
              <img src={IMAGECONFIG.NAVIGATION.SETTINGSLOGO} />
            </ListItemIcon>
            <ListItemText primary='Settings' />
          </ListItem>
        </List>
      </Drawer >
      <Routes>
        <Route exact path='/plans' element={<Plans />} />
        <Route path='/summary' element={<Summary />} />
        <Route path='/category-summary' element={<CategorySummary />} />
        <Route path='/key-accounts' element={<KeyAccounts />} />
        <Route path='/financial-summary' element={<KeyFinancials />} />
      </Routes>
    </div >
  )
}

export default App


